import React from 'react';
import { Container } from 'reactstrap';
import Img from 'gatsby-image';

const Jumbo = props => (
  <div
    style={{
      margin: 0, overflow: 'hidden', position: 'relative', zIndex: 2, minHeight: '25rem',
    }}
  >
 <Img
  className="filter"
  fluid={props.image}
  style={{
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,
  }}
/> 
    <Container>
      <div
        style={{
          position: 'relative',
          zIndex: '4',
          color: '#fff',
          padding: '2rem',
          textAlign: 'center',
          marginTop: '5rem',
        }}
      >
        <h1 style={{ color: '#fff', fontSize: '4rem' }}>{props.title}</h1>
        <p>{props.subTitle}</p>
      </div>
    </Container>
  </div>
);

export default Jumbo;
