import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Col } from 'reactstrap';

const Menu = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-left: 0px;
`;

const MenuItem = styled.li`
  margin-bottom: 10px;
  font-size: 90%;
`;

const Copyright = styled.div`
  margin: 10px;
  font-size: 90%;
  text-align: center;
`;

const Footer = () => (
  <Container fluid className="bg-light p-5">
    <Container>
      <Row>
        <Col sm="12" md="4">
          <h3>Accommodation</h3>
          <Menu>
            <MenuItem>
              <Link to="/byron-bay-accommodation/grand-buree">Grand Buree</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/byron-bay-accommodation/garden-burees">Garden Buree</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/byron-bay-accommodation/gallery-studio">The Gallery Studio</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/byron-bay-accommodation/birdsong-hideaway">Birdsong Hideaway</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/byron-bay-accommodation/dragonfly-rest">Dragonfly's Rest</Link>
            </MenuItem>
          </Menu>
        </Col>
        <Col sm="12" md="4">
          <h3>Quick Links</h3>
          <Menu>
            <MenuItem>
              <Link to="/facilities">Facilities</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/wellness">Wellbeing Packages</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/wedding">Weddings</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/info">Guest info</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/contact">Contact us</Link>
            </MenuItem>
          </Menu>
        </Col>
        <Col sm="12" md="4">
          <h3>Contact</h3>
          <p>Mobile: 02 6685 5390 </p>
          <p>Email: escape@gardenburees.com.au</p>
        </Col>
      </Row>
      <Row className="text-center py-4">
        <Col>
          <Copyright>
            Copyright Garden Burees Byron Bay 2018 -
            {' '}
            <Link to="/privacy">Privacy Policy</Link>
            {' '}
-
            {' '}
            <Link to="/terms">Terms and conditions</Link>
          </Copyright>
        </Col>
      </Row>
    </Container>
  </Container>
);

export default Footer;
