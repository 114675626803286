import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col } from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import ReactNavbar from './ReactNavbar';
import Footer from './footer';

// main site style
import '../layouts/index.scss';


export default ({ children }) => (
  <StaticQuery
    query={graphql`
         query LayoutQuery {
           site {
             siteMetadata {
               title
             }
           }
         }
       `}
    render={data => (
      <>
        <Helmet>
          <html lang="en-gb" />
          <meta
            name="google-site-verification"
            content="weWs1sVB3YSMZiMx2nYmkTJyRysmynf7gK2QertJL9I"
          />
          <link href="https://datocms-assets.com" rel="preconnect" crossOrigin />
          <link href="https://www.mews.li" rel="preconnect" crossOrigin />

          <script>
            {`(function(m,e,w,s){c=m.createElement(e);c.onload=function(){
Mews.D.apply(null,s)};c.async=1;c.src=w;t=m.getElementsByTagName(e)[0];t.parentNode.insertBefore(c,t);})
(document,'script','https://www.mews.li/distributor/distributor.min.js',[['b0501746-1c12-4558-bb5b-4dd9f7af1ffa']]);`}

          </script>
        </Helmet>
        <ReactNavbar />
        <main>

          {children}
        </main>
        <Footer />
      </>
    )}
  />
);
