import React from 'react';
import { Link } from 'gatsby';
import {
  Container, Collapse, Navbar, NavbarToggler, Nav,
} from 'reactstrap';
import logo from '../static/garden-buree-logo.jpg';

export default class ReactNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <Container>
        <Navbar light expand="lg">
          <Link to="/" className="navbarBrand d-block d-lg-none">
            <img src={logo} style={{ width: '70px', margin: 0 }} alt="Garden Burees in Byron Bay" />
          </Link>
          <NavbarToggler
            onClick={this.toggleNavbar}
            className="mr-2"
            style={{ fontSize: '1rem' }}
            aria-label="Open Mobile menu"
          />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar className="navbump">
              <Link className="nav-item nav-link" to="/byron-bay-accommodation">
                Accommodation
              </Link>
              <Link className="nav-item nav-link" to="/facilities">
                Facilities
              </Link>
              <Link className="nav-item nav-link" to="/wellness">
                Wellbeing Packages
              </Link>
            </Nav>
            <Link to="/" className="navbarBrand m-auto d-none d-lg-block">
              <img
                src={logo}
                style={{ width: '120px', margin: 0 }}
                alt="Garden Burees in Byron Bay"
              />
            </Link>

            <Nav className="ml-auto navbump" navbar>
              <Link className="nav-item nav-link" to="/wedding">
                Weddings
              </Link>
              <Link className="nav-item nav-link" to="/info">
                Guest Info
              </Link>
              <Link className="nav-item nav-link" to="/contact">
                Contact
              </Link>
              <a
                className="nav-item nav-link btn btn-primary distributor"
                style={{ color: '#fff', marginLeft: '5px' }}
                href="#"
              >
                Book Now
              </a>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    );
  }
}
