import React from 'react'
import { Container, Row, Col } from 'reactstrap'


const MainContent = props => (

  <Container className="py-4 narrow">
    <Row>
      <Col >
      <div dangerouslySetInnerHTML={{ __html: props.content }} />
      </Col>
    </Row>
  </Container>
  

  
);

export default MainContent


